.tab {
  /* font-size: 16px !important; */
  font-size: 14px !important;
  /* padding: 20px 35px; */
  padding: 12px 7px !important;
  font-weight: 700 !important;
  font-family: Mulish !important;
}

@media screen and (min-width: 1100px) {
  .tab {
    font-size: 15px !important;
  }
}

@media screen and (min-width: 1200px) {
  .tab {
    font-size: 15px !important;
    padding: 12px 16px !important;
  }
}

@media screen and (min-width: 1200px) {
  .tab {
    font-size: 15px !important;
    padding: 12px 16px !important;
  }
}

@media screen and (min-width: 1300px) {
  .tab {
    font-size: 17px !important;
    padding: 12px 16px !important;
  }
}

@media screen and (min-width: 1400px) {
  .tab {
    font-size: 18px !important;
  }
}
