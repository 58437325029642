.tag {
  appearance: none;
  outline: none;
  border: none;
  font-size: 30px;
  background: transparent;
  color: #585858;
  padding: 0;
  background-image: url(./creetDown.png);
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: calc(100% - 5px) center;
  padding-right: 30px;
}

.btnHover {
  font-size: 16px !important;
  height: 34px;
}

.btnHover:hover {
  transition: all 0.3s ease-in-out 0.2s;
  transform: scale(1.1);
}

@media screen and (min-width: 1400px) {
  .btnHover {
    font-size: 18px !important;
    height: 40px;
  }
}
