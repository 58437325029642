.chart_heading {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 31px;
  color: #737373;
  text-transform: capitalize;
}

@media screen and (min-width: 768px) {
  .chart_heading {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  .chart_heading {
    font-size: 17px;
  }
}

@media screen and (min-width: 1024px) {
  .chart_heading {
    font-size: 21px;
  }
}

@media screen and (min-width: 1200px) {
  .chart_heading {
    font-size: 21px;
  }
}
