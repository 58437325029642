#card {
  /* min-width: 226px; */
  /* max-width: fit-content; */
  width: 100%;
  /* height: 95px; */
  background: #f6f6f6;
  border-radius: 10px;
  box-shadow: none;
}

#cardInner {
  padding: 13px !important;
  padding-bottom: 13px !important;
}

#cardHeader {
  /* min-width: 236px; */
  /* width: max-content; */

  /* height: 22px; */
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  /* font-size: 17px; */
  /* line-height: 22px; */
  /* identical to box height */
  color: #737373;
}

#cardNumber {
  font-family: Mulish;
  font-style: normal;
  font-weight: 500;
  /* font-size: 30px; */
  font-size: 24px;
  /* line-height: 45px; */
  line-height: normal;
  /* identical to box height */
  color: #0092e8;
  overflow-wrap: break-word;
}

@media screen and (min-width: 768px) {
  #cardHeader {
    font-size: 10px;
  }
  #cardNumber {
    font-size: 13px;
  }
}

@media screen and (min-width: 1200px) {
  #cardHeader {
    font-size: 12px;
  }
  #cardNumber {
    font-size: 22px;
  }
}

@media screen and (min-width: 1400px) {
  #card {
    min-width: max-content;
  }
  #cardHeader {
    font-size: 14px;
  }
  #cardNumber {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  #cardHeader {
    font-size: 10px;
    overflow-wrap: break-word;
  }
  #cardNumber {
    font-size: 15px;
  }
}