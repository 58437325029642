#viewBtn {
  color: #afafaf !important;
  font-family: Mulish !important;
  font-size: 16px !important;
  padding: 0 !important;
  margin: 0 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  border-bottom: 1px solid #acacac !important;
}

#icon {
  font-size: 16px !important;
  color: #acacac !important;
  margin: 0 0 0 5px;
}

#viewBtn:hover {
  background-color: #ffffff;
}
