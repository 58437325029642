.shareCapitalFormHeader {
  width: 120px;
  height: 23px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #303030;
}
