.transparentButton {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 14.2029px;
  line-height: 18px;
  /* identical to box height */
  text-transform: uppercase;
  color: #828282;
  margin-right: 10px;
}

.normalButton {
  background: #c4c4c4;
  border-radius: 3.55072px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 14.2029px;
  line-height: 18px;
  /* identical to box height */
  text-transform: uppercase;
  color: #ffffff;
}

.configLeftHead {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #585858;
  padding: 30px 20px;
}

.configListItem {
  font-size: 17px;
  padding: 25px 10px 15px;
}

@media screen and (min-width: 1400px) {
  .configLeftHead {
    font-size: 26px;
  }
  .configListItem {
    font-size: 19px;
  }
}
