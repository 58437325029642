.sideNav {
  width: 16vw;
  background-color: #f3f3f3;
  min-height: 100vh;
  height: 100%;
  position: sticky;
  color: #525252;
  z-index: 6;
  overflow: auto;
  font-family: Mulish !important;
}

.sideNav a {
  text-decoration: none;
  color: #525252;
  font-family: Mulish !important;
}
.navList {
  margin-top: 15vh !important;
  position: fixed !important;
  width: 16vw;
  height: 85vh;
  overflow-y: auto;
}

.navLogo {
  margin-top: 3vh;
  margin-left: 15px;
  position: fixed;
  max-width: 12vw;
}
.active {
  color: #ffffff !important;
}

.navBtn img {
  width: 70%;
  filter: brightness(0.3);
}

.active .navBtn:hover {
  background-color: #0096FF !important;
}

.active .navBtn {
  background-color: #0096FF;
}

.active .reportBtn {
  color: #0096FF;
}

.active .navBtn img {
  filter: none !important;
}

/* .active img {
  mix-blend-mode: color;
} */
