.communicationPinIcon:hover {
  background-color: #c4c4c4;
}

.sendBtn {
  height: 34px;
  font-size: 16px;
}

.sendBtn:hover {
  transform: scale(1.1);
}

@media screen and (min-width: 1400px) {
  .sendBtn {
    height: 40px;
    font-size: 18px;
  }
}
