.back {
  width: 100%;
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 25px;
}

.content {
  width: 100%;
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 40px 40px 40px;
}

.saved {
  width: 100%;
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 25px;
  padding: 40px;
}

#title {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 50px;
  color: #434343;
}

#description {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  color: #434343;
}

.questionBack {
  width: 100%;
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.btnArrow {
  width: 35%;
  appearance: none;
  outline: none;
  border: none;
  font-size: 20px;
  background: #f0f0f0;
  border-radius: 5px;
  color: #505050;
  padding: 5px 10px;
  background-image: url(../../assets/images/caret-down.png);
  background-repeat: no-repeat;
  background-position: calc(100% - 25px) center;
  padding-right: 30px;
}

.exportBtn {
  background-color: #0096ff!important;
}

#btnArrow {
  width: 100px;
  appearance: none;
  outline: none;
  border: none;
  font-size: 20px;
  background: #f0f0f0;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 10px;
  background-image: url(../../assets/images/caret-down1.png);
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) center;
  padding-right: 0px;
}

.tag {
  appearance: none;
  outline: none;
  border: none;
  font-size: 30px;
  /* background: #ffffff; */
  color: #585858;
  padding: 5px 10px;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 5px) center;
  padding-right: 30px;
}

#tag {
  appearance: none;
  outline: none;
  border: none;
  font-size: 18px;
  background: none;
  color: #585858;
  padding: 5px 10px;
  background-image: url(../../assets/images/caret-down.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 5px) center;
  padding-right: 30px;
}

#limit {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #434343;
  text-align: center;
}

#limitLabel {
  font-size: 17px;
  font-family: Mulish;
  color: #585858;
}

#addMore {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height */
  color: #9e9e9e;
}

.inputBox {
  border: none;
  background: transparent;
  color: #9e9e9e;
  border: none;
  outline: none;
}

#submitBtn {
  background: #0094e7;
  /* width: 157px; */
  padding: 2px 10px;
  height: 34px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
}

#submitBtn:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#saveBtn {
  background: #0096ff;
  width: 120px;
  height: 34px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
  margin-right: 20px;
}

#saveBtn:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#launch {
  background: #0096ff;
  width: 120px;
  height: 40px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
}

#launch:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#cancelBtn {
  width: 80px;
  height: 34px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: #c4c4c4;
  margin-right: 30px;
}
#cancelBtn:hover {
  background-color: #ffffff;
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#cardlabel {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* identical to box height */
  text-transform: uppercase;
  color: #000000;
}

#cardValue {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
  /* color: #585858; */
  color: #0096ff;
}

#scrollBar {
  overflow-y: scroll;
  height: 76.2vh;
}

#scrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#scrollBar::-webkit-scrollbar {
  display: none;
}

#selectBtn {
  font-size: 16px;
  font-weight: 600;
  font-family: Mulish;
  color: black;
  text-transform: capitalize;
  background: #f0f0f0;
  border-radius: 5px;
}

#selectBtn:hover {
  background-color: #0094e7;
  color: #ffffff !important;
  border-radius: 0;
}

#selBtn {
  display: flex;
  justify-content: space-around;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  text-transform: capitalize;
  /* identical to box height */
  color: #505050;
  background: #f0f0f0;
  border-radius: 5px;
}

#typeBtn {
  display: flex;
  justify-content: flex-start;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  text-transform: capitalize;
  /* identical to box height */
  color: #505050;
  border-radius: 5px;
}

#savedVal {
  font-family: Mulish;
  color: #ffffff;
  background-color: #c4c4c4;
  /* margin-right: 20px; */
  font-weight: 600;
  font-size: 18px;
  padding: 5px;
  margin: 1rem 3rem 2rem 0;
}
.headerCardLabel {
  font-size: 12.8px;
  padding: 5px 0 0 10px;
}

.headerCardValue {
  font-size: 24px;
  color: #0096ff;
}

.headerCardStatus {
  margin: -10px 0 0 0;
  font-size: 12px;
}

.headerCardTotal {
  font-size: 12.8px;
  margin: 0px 0 0 10px;
}

.valueStatus {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 10px;
  padding-bottom: 2px;
  margin-top: -8px;
}

@media screen and (min-width: 1400px) {
  .headerCardValue {
    font-size: 34px;
  }

  .headerCardStatus {
    /* margin: -10px 0 0 0; */
    font-size: 16px;
  }

  .valueStatus {
    margin: -15px 10px 0 0;
  }

  #submitBtn {
    font-size: 18px;
    height: 40px;
  }

  #cardlabel {
    font-size: 16px;
  }

  #cardValue {
    font-size: 25px;
  }

  .back,
  .content,
  .questionBack,
  .formBtn,
  .saved {
    width: 956px;
  }

  #selBtn,
  #typeBtn,
  #limitLabel {
    font-size: 20px;
  }

  #description {
    font-size: 17px;
  }

  #title {
    font-size: 40px;
  }
}
