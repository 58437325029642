.exportBtn {
  width: 40px;
  height: 34px;
  padding: 5px 10px;
  background-color: #0096ff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1400px) {
  .exportBtn {
    height: 40px;
  }
}
