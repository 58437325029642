.logoHead {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 41.9649px;
  line-height: 24px;
  /* identical to box height, or 58% */
  text-transform: uppercase;

  letter-spacing: 0.47em;

  color: #000000;
  margin: 10px;
}

.cooperative {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 17.4854px;
  line-height: 24px;
  /* identical to box height, or 140% */

  letter-spacing: 0.5em;
  text-transform: uppercase;
  color: #000000;
  margin: 10px;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4.5rem 0 1rem 0;
  overflow: hidden;
}
.forgot {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #7c7c7c;
}

