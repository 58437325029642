.name {
  font-size: 17px;
}

.id {
  font-size: 12px;
  margin-bottom: 0;
  text-transform: none;
}

@media screen and (min-width: 1400px) {
  .name {
    font-size: 20px;
  }

  .id {
    font-size: 15px;
    text-transform: none;
  }
}
