.tag {
  appearance: none;
  outline: none;
  border: none;
  font-size: 25px;
  background: #ffffff;
  color: #585858;
  background-image: url(../../assets/images/caret-down.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 5px) center;
  padding-right: 30px;
}

.btnArrow {
  appearance: none;
  outline: none;
  border: none;
  font-size: 20px;
  background: #c4c4c4;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 10px;
  background-image: url(../../assets/images/caret-down1.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 5px) center;
  padding-right: 30px;
  text-align: right;
}

.gridUsers {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  width: 100%;
  margin: 20px 20px;
}

#userLabel {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  line-height: 20px;
  text-transform: capitalize;
  /* identical to box height */
  color: #595959;
}

#errors {
  margin: 0;
  padding: 0;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  /* identical to box height */
  color: red;
}

#fileUploadBtn {
  background: #0096FF;
  width: 80px;
  height: 30px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 10px 20px 0 0;
  border-radius: 5px;
}

#fileUploadBtn:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#cancelFile {
  width: 80px;
  height: 30px;
  margin: 10px 20px 0 0;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
  text-transform: uppercase;
  color: #c4c4c4;
}
#cancelFile:hover {
  background-color: #ffffff;
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#scrollBar {
  overflow-y: scroll;
  height: 76.2vh;
}

#scrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#scrollBar::-webkit-scrollbar {
  display: none;
}

#icon {
  font-size: 16px !important;
  color: #acacac !important;
  cursor: pointer;
}

/* #icon:hover {
  content: "hello";
} */

.userSelectField,
.userInput {
  font-size: 13.5px !important;
}

@media screen and (min-width: 1200px) {
  #userLabel {
    font-size: 13px;
  }
  .userSelectField,
  .userInput {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1400px) {
  #userLabel {
    font-size: 15px;
  }

  .userSelectField,
  .userInput {
    font-size: 17px !important;
  }
}
