@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@600;700&display=swap");

/* USE INCASE OF EMERGENCY */
/* html {
  zoom: 0.8;
} */

body {
  margin: 0;
  font-family: Mulish !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

p {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  color: #737373;
  text-transform: uppercase;
}

.sideNavbar .MuiListItemIcon-root {
  min-width: 30px !important;
}

.sideNavbar .MuiTypography-root {
  font-size: 0.85rem !important;
}
.reportCollapse .MuiTypography-root {
  font-size: 0.83rem !important;
}

.MuiTableCell-head {
  font-weight: 700 !important;
}

.MuiTableCell-root {
  font-family: Mulish !important;
}

.MuiTableCell-head,
.MuiTableCell-root,
.table_date {
  font-size: 11px !important;
}

.table_time {
  font-size: 10.5px !important;
}

/* .MuiTableCell-footer {
  padding: 0 !important;
} */

.MuiToolbar-root {
  min-height: unset !important;
  padding: 10px;
}

.MuiTableCell-head .MuiButtonBase-root {
  padding: 0 7px !important;
}

.MuiTableCell-root .MuiButtonBase-root {
  padding: 0 10px;
}

/* @media screen and (min-width: 992px) {
  
} */

@media screen and (min-width: 1400px) {
  .sideNavbar .MuiTypography-root {
    font-size: 0.9rem !important;
  }
  .reportCollapse .MuiTypography-root {
    font-size: 0.87rem !important;
  }

  .MuiTableCell-head,
  .MuiTableCell-root,
  .table_date {
    font-size: 12px !important;
  }

  .table_time {
    font-size: 11.5px !important;
  }
}

.shareholders-container tr th{
  font-size: 13px !important;
  padding: 5px;
  line-height: unset !important;
}
@media screen and (max-width: 768px) {
  .dashboard-cards{
    width: 100% !important;
    padding: 4px;
    max-width: 50% !important;
  }
}
.date-provider label{
  top: -9px !important;
  font-size: 15px;
}
.date-provider input{
  padding: 5px !important;
}