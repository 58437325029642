#userInput {
  font-family: Mulish;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 3px;
  /* width: 342px; */
  height: 50px;
  outline: none;
}

#userLabel {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  color: #595959;
}

#selectField {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height */
  color: #585858;
}

#selectOption {
  font-size: 16px;
  font-weight: 600;
  font-family: Mulish;
  color: black;
  text-transform: capitalize;
}

#selectOption:hover {
  color: #ffffff;
  background-color: #0094e7;
}
