.tag {
    appearance: none;
    outline: none;
    border: none;
    font-size: 30px;
    background: transparent;
    color: #585858;
    padding: 0;
    background-image: url(../Users/creetDown.png);
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: calc(100% - 5px) center;
    padding-right: 30px;
  }