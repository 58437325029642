#userInput {
  font-family: Mulish;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 3px;
  /* width: 342px; */
  height: 50px;
  outline: none;
}

#userLabel {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  line-height: 20px;
  text-transform: capitalize;
  /* identical to box height */
  color: #595959;
}

#error {
  color: red;
  text-transform: capitalize;
  font-family: Mulish;
}

#submitBtn {
  background: #0094e7;
  width: 138px;
  height: 34px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
}

#submitBtn:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#cancelBtn {
  width: 138px;
  height: 40px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #c4c4c4;
  margin-right: 20px;
}

#cancelBtn:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

@media screen and (min-width: 1200px) {
  #userLabel {
    font-size: 13px;
  }
}

@media screen and (min-width: 1400px) {
  #userLabel {
    font-size: 15px;
  }
  #submitBtn {
    font-size: 18px;
    height: 40px;
  }
}
