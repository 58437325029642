/* .Header{
    display: flex;
    align-items: center;
    justify-content: space-between;
} */

.end {
  width: 30%;
}

.addBtn {
  font-size: 16px !important;
  /* padding: 2px 10px !important; */
  height: 34px !important;
  min-width: auto !important;
}

.addBtn:hover {
  transition: all 0.3s ease-in-out 0.2s;
  transform: scale(1.1);
}

.share_capital_dropdown {
  position: absolute;
  top: 2%;
  right: 2%;
  z-index: 200;
}

.sharecapitalHeading {
  font-size: 23px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  color: #585858;
}
.sharecapitalCard {
  min-width: 30%;
}
@media screen and (min-width: 1400px) {
  .sharecapitalCard {
    min-width: 15%;
  }
  .sharecapitalHeading {
    font-size: 25px;
  }
  .addBtn {
    height: 40px !important;
  }
}
