#btn {
  background: #0094e7;
  color: #ffffff;
  /* width: 110px; */
  white-space: nowrap;
  height: 34px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
}

#menuItem {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
}

#menuItem:hover {
  background: #0096FF;
  color: #ffffff;
}

#menuBtn {
  cursor: default;
  background-color: #ffffff;
  padding-top: 0;
  padding-bottom: 0;
}

#menuLabel {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  color: #787878;
}

#LogProfBtn {
  font-family: Mulish;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  color: #626262;
}

#LogProfBtn:hover {
  background-color: #ffffff;
}

/* #exportBtn {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
} */

@media screen and (min-width: 1100px) {
  #btn {
    font-size: 13.5px;
  }
}

@media screen and (min-width: 1300px) {
  #btn {
    font-size: 14px;
  }
}

@media screen and (min-width: 1400px) {
  #btn {
    font-size: 16px;
    height: 40px;
  }
}
