.container {
  margin: 40px 10px;
}

.barGrid {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  /* flex-shrink: 1; */
  padding: 0px 2px 20px;
  row-gap: 20px;
  column-gap: 10px;
}

.dashboardCard {
  /* min-width: 192px; */
  max-width: 16%;
  justify-content: center;
}

@media screen and (min-width: 1400px) {
  .dashboardCard {
    min-width: 14%;
  }
}

.barChart {
  margin-top: 50px;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.graphWH {
  width: 792px;
  height: 388px;
}

@media screen and (min-width: 1200px) {
  .barGrid > * {
    font-size: 20px !important;
  }
}
