#selectBtn {
  font-size: 16px;
  font-weight: 600;
  font-family: Mulish;
  color: black;
  text-transform: capitalize;
}

#selectBtn:hover {
  background-color: #0096FF;
  color: #ffffff !important;
}

#selBtn {
  display: flex;
  align-items: center;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  /* font-size: 20px; */
  /* background-color: #0096FF; */
  font-size: 17px;
  line-height: 38px;
  text-transform: capitalize;
  /* identical to box height */
  color: #585858;
}

@media screen and (min-width: 1100px) {
  #selBtn {
    font-size: 18px;
  }
}

@media screen and (min-width: 1200px) {
  #selBtn {
    font-size: 19px;
  }
}

@media screen and (min-width: 1300px) {
  #selBtn {
    font-size: 20px;
  }
}

@media screen and (min-width: 1400px) {
  #selBtn {
    font-size: 23px;
  }
}
