@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@600;700&display=swap);
/* USE INCASE OF EMERGENCY */
/* html {
  zoom: 0.8;
} */

body {
  margin: 0;
  font-family: Mulish !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

p {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  color: #737373;
  text-transform: uppercase;
}

.sideNavbar .MuiListItemIcon-root {
  min-width: 30px !important;
}

.sideNavbar .MuiTypography-root {
  font-size: 0.85rem !important;
}
.reportCollapse .MuiTypography-root {
  font-size: 0.83rem !important;
}

.MuiTableCell-head {
  font-weight: 700 !important;
}

.MuiTableCell-root {
  font-family: Mulish !important;
}

.MuiTableCell-head,
.MuiTableCell-root,
.table_date {
  font-size: 11px !important;
}

.table_time {
  font-size: 10.5px !important;
}

/* .MuiTableCell-footer {
  padding: 0 !important;
} */

.MuiToolbar-root {
  min-height: unset !important;
  padding: 10px;
}

.MuiTableCell-head .MuiButtonBase-root {
  padding: 0 7px !important;
}

.MuiTableCell-root .MuiButtonBase-root {
  padding: 0 10px;
}

/* @media screen and (min-width: 992px) {
  
} */

@media screen and (min-width: 1400px) {
  .sideNavbar .MuiTypography-root {
    font-size: 0.9rem !important;
  }
  .reportCollapse .MuiTypography-root {
    font-size: 0.87rem !important;
  }

  .MuiTableCell-head,
  .MuiTableCell-root,
  .table_date {
    font-size: 12px !important;
  }

  .table_time {
    font-size: 11.5px !important;
  }
}

.shareholders-container tr th{
  font-size: 13px !important;
  padding: 5px;
  line-height: unset !important;
}
@media screen and (max-width: 768px) {
  .dashboard-cards{
    width: 100% !important;
    padding: 4px;
    max-width: 50% !important;
  }
}
.date-provider label{
  top: -9px !important;
  font-size: 15px;
}
.date-provider input{
  padding: 5px !important;
}
.Sidenav_sideNav__3Pbak {
  width: 16vw;
  background-color: #f3f3f3;
  min-height: 100vh;
  height: 100%;
  position: -webkit-sticky;
  position: sticky;
  color: #525252;
  z-index: 6;
  overflow: auto;
  font-family: Mulish !important;
}

.Sidenav_sideNav__3Pbak a {
  text-decoration: none;
  color: #525252;
  font-family: Mulish !important;
}
.Sidenav_navList__1Teah {
  margin-top: 15vh !important;
  position: fixed !important;
  width: 16vw;
  height: 85vh;
  overflow-y: auto;
}

.Sidenav_navLogo__2bKPf {
  margin-top: 3vh;
  margin-left: 15px;
  position: fixed;
  max-width: 12vw;
}
.Sidenav_active__3getE {
  color: #ffffff !important;
}

.Sidenav_navBtn__2BHoR img {
  width: 70%;
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
}

.Sidenav_active__3getE .Sidenav_navBtn__2BHoR:hover {
  background-color: #0096FF !important;
}

.Sidenav_active__3getE .Sidenav_navBtn__2BHoR {
  background-color: #0096FF;
}

.Sidenav_active__3getE .Sidenav_reportBtn__1EaBh {
  color: #0096FF;
}

.Sidenav_active__3getE .Sidenav_navBtn__2BHoR img {
  -webkit-filter: none !important;
          filter: none !important;
}

/* .active img {
  mix-blend-mode: color;
} */

#Cards_card__1ak5A {
  /* min-width: 226px; */
  /* max-width: fit-content; */
  width: 100%;
  /* height: 95px; */
  background: #f6f6f6;
  border-radius: 10px;
  box-shadow: none;
}

#Cards_cardInner__2EwNs {
  padding: 13px !important;
  padding-bottom: 13px !important;
}

#Cards_cardHeader__2MA3g {
  /* min-width: 236px; */
  /* width: max-content; */

  /* height: 22px; */
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  /* font-size: 17px; */
  /* line-height: 22px; */
  /* identical to box height */
  color: #737373;
}

#Cards_cardNumber__1-fVo {
  font-family: Mulish;
  font-style: normal;
  font-weight: 500;
  /* font-size: 30px; */
  font-size: 24px;
  /* line-height: 45px; */
  line-height: normal;
  /* identical to box height */
  color: #0092e8;
  overflow-wrap: break-word;
}

@media screen and (min-width: 768px) {
  #Cards_cardHeader__2MA3g {
    font-size: 10px;
  }
  #Cards_cardNumber__1-fVo {
    font-size: 13px;
  }
}

@media screen and (min-width: 1200px) {
  #Cards_cardHeader__2MA3g {
    font-size: 12px;
  }
  #Cards_cardNumber__1-fVo {
    font-size: 22px;
  }
}

@media screen and (min-width: 1400px) {
  #Cards_card__1ak5A {
    min-width: -webkit-max-content;
    min-width: max-content;
  }
  #Cards_cardHeader__2MA3g {
    font-size: 14px;
  }
  #Cards_cardNumber__1-fVo {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  #Cards_cardHeader__2MA3g {
    font-size: 10px;
    overflow-wrap: break-word;
  }
  #Cards_cardNumber__1-fVo {
    font-size: 15px;
  }
}
.Charts_chart_heading__GzB9F {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 31px;
  color: #737373;
  text-transform: capitalize;
}

@media screen and (min-width: 768px) {
  .Charts_chart_heading__GzB9F {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  .Charts_chart_heading__GzB9F {
    font-size: 17px;
  }
}

@media screen and (min-width: 1024px) {
  .Charts_chart_heading__GzB9F {
    font-size: 21px;
  }
}

@media screen and (min-width: 1200px) {
  .Charts_chart_heading__GzB9F {
    font-size: 21px;
  }
}

.Component-horizontalScrollContainer-12 {
  max-height: 60vh;
}


.Dashboard_container__3w84- {
  margin: 40px 10px;
}

.Dashboard_barGrid__2JmG1 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  /* flex-shrink: 1; */
  padding: 0px 2px 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.Dashboard_dashboardCard__1LUrd {
  /* min-width: 192px; */
  max-width: 16%;
  justify-content: center;
}

@media screen and (min-width: 1400px) {
  .Dashboard_dashboardCard__1LUrd {
    min-width: 14%;
  }
}

.Dashboard_barChart__1aXvg {
  margin-top: 50px;
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.Dashboard_graphWH__1zpxn {
  width: 792px;
  height: 388px;
}

@media screen and (min-width: 1200px) {
  .Dashboard_barGrid__2JmG1 > * {
    font-size: 20px !important;
  }
}

/* .Header{
    display: flex;
    align-items: center;
    justify-content: space-between;
} */

.end {
  width: 30%;
}

.addBtn {
  font-size: 16px !important;
  /* padding: 2px 10px !important; */
  height: 34px !important;
  min-width: auto !important;
}

.addBtn:hover {
  transition: all 0.3s ease-in-out 0.2s;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.share_capital_dropdown {
  position: absolute;
  top: 2%;
  right: 2%;
  z-index: 200;
}

.sharecapitalHeading {
  font-size: 23px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  color: #585858;
}
.sharecapitalCard {
  min-width: 30%;
}
@media screen and (min-width: 1400px) {
  .sharecapitalCard {
    min-width: 15%;
  }
  .sharecapitalHeading {
    font-size: 25px;
  }
  .addBtn {
    height: 40px !important;
  }
}

#GeneralInputField_userInput__2nTFR {
  font-family: Mulish;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 3px;
  /* width: 342px; */
  height: 50px;
  outline: none;
}

#GeneralInputField_userLabel__1-6iP {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  color: #595959;
}

#GeneralInputField_selectField__30SI1 {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height */
  color: #585858;
}

#GeneralInputField_selectOption__h4VaC {
  font-size: 16px;
  font-weight: 600;
  font-family: Mulish;
  color: black;
  text-transform: capitalize;
}

#GeneralInputField_selectOption__h4VaC:hover {
  color: #ffffff;
  background-color: #0094e7;
}

.member_tag__3O_6W {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  font-size: 22px;
  background: #ffffff;
  color: #585858;
  /* padding: 5px 10px; */
  /* background-image: url(../../assets/images/caret-down.png); */
  /* background-repeat: no-repeat;
  background-size: 15px; */
  /* background-position: calc(100%) center; */
  width: 120px;
  height: 38px;
}

.member_btnArrow__2QoYT {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  font-size: 150px;
  background: #c4c4c4;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAJCAYAAADtj3ZXAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABrSURBVHgBnZDRDYAgDERPJ3AERmAkRmEE3cCNGEE3cIR6jXxoQsV6CZC0fUfbQUQ2AAF+7SOvBf90cfw9i0/5YcPA+hGcm30wUTpgMYdgctIFGqDGA96kBQ2DPngziDxHBfWN8IhAqnCyak7ZNN/qv19tugAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 5px) center;
  padding-right: 30px;
}

.member_cardLabel__2QpCH {
  text-transform: capitalize;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  /* font-size: 14px; */
  font-size: 12px;
  line-height: 25px;
  /* identical to box height */
  color: #000001 !important;
  margin-bottom: 1rem !important;
}

.member_cardValue__2mEs6 {
  /* font-size: 30px; */
  font-size: 26px;
  margin-top: -15px;
  color: #0094e7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
}

#member_cardImg__2Wshy {
  position: relative;
  bottom: 5px;
  left: 10px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

#member_lab__3B8Q_ {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  text-transform: capitalize;
  color: #595959;
}

#member_but__38ots {
  background: #c4c4c4;
  border-radius: 5px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;
}

#member_cardMember__3cFcZ {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #595959;
}

#member_cardNumber__19ZCo {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 44px;
  /* text-align: center; */
  /* identical to box height */

  color: #000000;
}

#member_issueBtn__35xh1 {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 15.0725px;
  line-height: 19px;
  /* identical to box height */
  width: 104px;
  height: 30.14px;
  text-transform: uppercase;
  background: #0096FF;
  border-radius: 3.76812px;
  color: #ffffff;
}

#member_userLabel__1frZH {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  line-height: 20px;
  text-transform: capitalize;
  /* identical to box height */
  color: #595959;
}

#member_errors__2q6Eq {
  margin: 0;
  padding: 0;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  /* identical to box height */
  color: red;
}

#member_submitBtn__3dOOF {
  background: #0094e7;
  width: 138px;
  height: 40px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
}

#member_submitBtn__3dOOF:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#member_cancelBtn__1Xwn2 {
  width: 138px;
  height: 40px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #c4c4c4;
  margin-right: 20px;
}

#member_cancelBtn__1Xwn2:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}
#member_headerBtn__2-Kyh {
  background: #0096FF;
  width: 130px;
  height: 34px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 13.5px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
}

#member_headerBtn__2-Kyh:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#member_inputField__2Cpry {
  font-family: Mulish;
}

#member_fileUploadBtn__2C1gG {
  background: #0096FF;
  width: 80px;
  height: 30px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 10px 20px 0 0;
  border-radius: 5px;
}

#member_fileUploadBtn__2C1gG:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#member_cancelFile__3mhAb {
  width: 80px;
  height: 30px;
  margin: 10px 20px 0 0;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
  text-transform: uppercase;
  color: #c4c4c4;
}
#member_cancelFile__3mhAb:hover {
  background-color: #ffffff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#member_scrollBar__23wgW {
  overflow-y: scroll;
  /* height: 76.2vh; */
}

#member_scrollBar__23wgW {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#member_scrollBar__23wgW::-webkit-scrollbar {
  display: none;
}

.member_leftPanelHead__2y_7s button {
  font-size: 18px !important;
}

.member_memberInput__1drt0 {
  font-size: 13.5px !important;
}

.member_memberSelectField___JQl8 {
  font-size: 13.5px !important;
}

.member_memberInfoLeftCol__82S3H {
  flex: 0 0 auto;
  width: 41% !important;
}

.member_memberInfoRightCol__3-XJG {
  flex: 0 0 auto;
  width: 59% !important;
}

.member_switchLabel__1B_-b {
  font-size: 17px !important;
  text-transform: capitalize;
  color: #666666;
}

@media screen and (min-width: 1024px) {
  .member_memberInfoLeftCol__82S3H {
    flex: 0 0 auto;
    width: 38% !important;
  }
  .member_memberInfoRightCol__3-XJG {
    flex: 0 0 auto;
    width: 62% !important;
  }
  .member_memberInput__1drt0 {
    font-size: 13.5px !important;
  }
  .member_memberSelectField___JQl8 {
    font-size: 13.5px !important;
  }
  .member_cardLabel__2QpCH {
    font-size: 13px;
  }
  .member_cardValue__2mEs6 {
    font-size: 24px;
  }
}

@media screen and (min-width: 1200px) {
  .member_memberInfoLeftCol__82S3H {
    flex: 0 0 auto;
    width: 37% !important;
  }
  .member_memberInfoRightCol__3-XJG {
    flex: 0 0 auto;
    width: 63% !important;
  }
  .member_memberInput__1drt0 {
    font-size: 14px !important;
  }
  .member_memberSelectField___JQl8 {
    font-size: 14px !important;
  }
  #member_userLabel__1frZH {
    font-size: 13px !important;
  }
  .member_switchLabel__1B_-b {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 1300px) {
  .member_memberInfoLeftCol__82S3H {
    flex: 0 0 auto;
    width: 36% !important;
  }
  .member_memberInfoRightCol__3-XJG {
    flex: 0 0 auto;
    width: 64% !important;
  }
  #member_headerBtn__2-Kyh {
    font-size: 14px;
  }
}

@media screen and (min-width: 1400px) {
  #member_headerBtn__2-Kyh {
    font-size: 16px;
    height: 40px;
    width: 140px;
  }
  .member_leftPanelHead__2y_7s {
    font-size: 25px;
  }
  #member_userLabel__1frZH {
    font-size: 15px !important;
  }
  .member_memberInput__1drt0 {
    font-size: 17px !important;
  }
  .member_memberSelectField___JQl8 {
    font-size: 17px !important;
  }
  .member_cardLabel__2QpCH {
    font-size: 14px;
  }
  .member_cardValue__2mEs6 {
    font-size: 30px;
  }
  #member_cardImg__2Wshy {
    height: 30px;
    width: 30px;
  }
}

/* @media screen and (min-width: 1500px) {
  .memberInfoLeftCol {
    flex: 0 0 auto;
    width: 34% !important;
  }
  .memberInfoRightCol{
    flex: 0 0 auto;
    width: 66% !important;
  }
} */

@media screen and (min-width: 1600px) {
  .member_memberInfoLeftCol__82S3H {
    flex: 0 0 auto;
    width: 34% !important;
  }
  .member_memberInfoRightCol__3-XJG {
    flex: 0 0 auto;
    width: 66% !important;
  }
}

@media screen and (min-width: 1700px) {
  .member_memberInfoLeftCol__82S3H {
    flex: 0 0 auto;
    width: 33.33% !important;
  }
  .member_memberInfoRightCol__3-XJG {
    flex: 0 0 auto;
    width: 66.67% !important;
  }
}

/* @media screen and (min-width: 1800px) {
  .memberInfoLeftCol {
    flex: 0 0 auto;
    width: 28% !important;
  }
}

@media screen and (min-width: 1900px) {
  .memberInfoLeftCol {
    flex: 0 0 auto;
    width: 27% !important;
  }
} */

.shareCapitalFormHeader {
  width: 120px;
  height: 23px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #303030;
}

/* .dropDownBtn:hover{
  
  transform: scale(1.1);
} */

.exportBtn {
  width: 40px;
  height: 34px;
  padding: 5px 10px;
  background-color: #0096ff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1400px) {
  .exportBtn {
    height: 40px;
  }
}

#UserInput_userInput__g804c {
  font-family: Mulish;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 3px;
  /* width: 342px; */
  height: 50px;
  outline: none;
}

#UserInput_userLabel__14Zuq {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  line-height: 20px;
  text-transform: capitalize;
  /* identical to box height */
  color: #595959;
}

#UserInput_error__1lsSY {
  color: red;
  text-transform: capitalize;
  font-family: Mulish;
}

#UserInput_submitBtn__3y29I {
  background: #0094e7;
  width: 138px;
  height: 34px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
}

#UserInput_submitBtn__3y29I:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#UserInput_cancelBtn__1jNTx {
  width: 138px;
  height: 40px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #c4c4c4;
  margin-right: 20px;
}

#UserInput_cancelBtn__1jNTx:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

@media screen and (min-width: 1200px) {
  #UserInput_userLabel__14Zuq {
    font-size: 13px;
  }
}

@media screen and (min-width: 1400px) {
  #UserInput_userLabel__14Zuq {
    font-size: 15px;
  }
  #UserInput_submitBtn__3y29I {
    font-size: 18px;
    height: 40px;
  }
}

#MenuDropdown_btn__-syZP {
  background: #0094e7;
  color: #ffffff;
  /* width: 110px; */
  white-space: nowrap;
  height: 34px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
}

#MenuDropdown_menuItem__UbHqX {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
}

#MenuDropdown_menuItem__UbHqX:hover {
  background: #0096FF;
  color: #ffffff;
}

#MenuDropdown_menuBtn__2RFki {
  cursor: default;
  background-color: #ffffff;
  padding-top: 0;
  padding-bottom: 0;
}

#MenuDropdown_menuLabel__F-cnR {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  color: #787878;
}

#MenuDropdown_LogProfBtn__3f4WA {
  font-family: Mulish;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  color: #626262;
}

#MenuDropdown_LogProfBtn__3f4WA:hover {
  background-color: #ffffff;
}

/* #exportBtn {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
} */

@media screen and (min-width: 1100px) {
  #MenuDropdown_btn__-syZP {
    font-size: 13.5px;
  }
}

@media screen and (min-width: 1300px) {
  #MenuDropdown_btn__-syZP {
    font-size: 14px;
  }
}

@media screen and (min-width: 1400px) {
  #MenuDropdown_btn__-syZP {
    font-size: 16px;
    height: 40px;
  }
}

.users_tag__13bBV {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  font-size: 25px;
  background: #ffffff;
  color: #585858;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAMCAYAAABm+U3GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADGSURBVHgBrdPRCYMwEAbgO12gIzhCV3AEJ2gFX3yqo7TvinUSV+gGzQh9TR6S3kGFYJIaMT8IIXf3cYLiMAxvACggbUSmtZ4gcdhEPvR9f0fEGySIMebRNE2HywXhT8IvcCCEToRe+Yx24Qhuo5zMLuZ53hH8gp3hGRt14LquP9RU7sG5l2ece1/zOI4nerWZnjNEoLzQupb5Bn6bV3QUEI6QUlY+NLjxEtq8oG9yBvcHEkqpsm1bEZr9CwfwTTQKXuEQg3K+zFVjX7HaH4gAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 5px) center;
  padding-right: 30px;
}

.users_btnArrow__1YnNm {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  font-size: 20px;
  background: #c4c4c4;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAJCAYAAADtj3ZXAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABrSURBVHgBnZDRDYAgDERPJ3AERmAkRmEE3cCNGEE3cIR6jXxoQsV6CZC0fUfbQUQ2AAF+7SOvBf90cfw9i0/5YcPA+hGcm30wUTpgMYdgctIFGqDGA96kBQ2DPngziDxHBfWN8IhAqnCyak7ZNN/qv19tugAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 5px) center;
  padding-right: 30px;
  text-align: right;
}

.users_gridUsers__2ZEr9 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
  width: 100%;
  margin: 20px 20px;
}

#users_userLabel__2jjhz {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  line-height: 20px;
  text-transform: capitalize;
  /* identical to box height */
  color: #595959;
}

#users_errors__1AhF- {
  margin: 0;
  padding: 0;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  /* identical to box height */
  color: red;
}

#users_fileUploadBtn__70CyQ {
  background: #0096FF;
  width: 80px;
  height: 30px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 10px 20px 0 0;
  border-radius: 5px;
}

#users_fileUploadBtn__70CyQ:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#users_cancelFile__f8bJN {
  width: 80px;
  height: 30px;
  margin: 10px 20px 0 0;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
  text-transform: uppercase;
  color: #c4c4c4;
}
#users_cancelFile__f8bJN:hover {
  background-color: #ffffff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#users_scrollBar__2lM7Y {
  overflow-y: scroll;
  height: 76.2vh;
}

#users_scrollBar__2lM7Y {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#users_scrollBar__2lM7Y::-webkit-scrollbar {
  display: none;
}

#users_icon__2Acme {
  font-size: 16px !important;
  color: #acacac !important;
  cursor: pointer;
}

/* #icon:hover {
  content: "hello";
} */

.users_userSelectField__2YZPD,
.users_userInput__usRiT {
  font-size: 13.5px !important;
}

@media screen and (min-width: 1200px) {
  #users_userLabel__2jjhz {
    font-size: 13px;
  }
  .users_userSelectField__2YZPD,
  .users_userInput__usRiT {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1400px) {
  #users_userLabel__2jjhz {
    font-size: 15px;
  }

  .users_userSelectField__2YZPD,
  .users_userInput__usRiT {
    font-size: 17px !important;
  }
}

#Dialog_viewBtn__Vtn5j {
  color: #afafaf !important;
  font-family: Mulish !important;
  font-size: 16px !important;
  padding: 0 !important;
  margin: 0 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  border-bottom: 1px solid #acacac !important;
}

#Dialog_icon__1oI6H {
  font-size: 16px !important;
  color: #acacac !important;
  margin: 0 0 0 5px;
}

#Dialog_viewBtn__Vtn5j:hover {
  background-color: #ffffff;
}

#MultiSelect_selectBtn__3rJrZ {
  font-size: 16px;
  font-weight: 600;
  font-family: Mulish;
  color: black;
  text-transform: capitalize;
}

#MultiSelect_selectBtn__3rJrZ:hover {
  background-color: #0096FF;
  color: #ffffff !important;
}

#MultiSelect_selBtn__1u-M0 {
  display: flex;
  align-items: center;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  /* font-size: 20px; */
  /* background-color: #0096FF; */
  font-size: 17px;
  line-height: 38px;
  text-transform: capitalize;
  /* identical to box height */
  color: #585858;
}

@media screen and (min-width: 1100px) {
  #MultiSelect_selBtn__1u-M0 {
    font-size: 18px;
  }
}

@media screen and (min-width: 1200px) {
  #MultiSelect_selBtn__1u-M0 {
    font-size: 19px;
  }
}

@media screen and (min-width: 1300px) {
  #MultiSelect_selBtn__1u-M0 {
    font-size: 20px;
  }
}

@media screen and (min-width: 1400px) {
  #MultiSelect_selBtn__1u-M0 {
    font-size: 23px;
  }
}

.tag {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  font-size: 30px;
  background: transparent;
  color: #585858;
  padding: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAMCAYAAABm+U3GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADBSURBVHgBrY/PDcIgFIcf7QKO0BFcoSN0Am3TS092FL0TsJO4ghvICF7hgu+ZNiEUlKZ8CRzen48fTAjxstZWkBdVoHSC/EyMbs75lTF2gQyg59Z13ciWAsrvWDzBDuj3fd+fvw+4jT1yV0oUbrMsyxHFT9gI7bjSlbht2zcO1VvkNEs7q3poWEp5wK898BwhQUqB/F4RWpiTN3gUxFFa6yYkjSZewOTVnLzypcaYehgGFdv9KY7I/0qTxJ4cUqTEBzuyaWtq4bneAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: calc(100% - 5px) center;
  padding-right: 30px;
}

.DataAcq_back__3qAr5 {
  width: 100%;
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 25px;
}

.DataAcq_content__u2ZBx {
  width: 100%;
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 40px 40px 40px;
}

.DataAcq_saved__eQ4Wy {
  width: 100%;
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 25px;
  padding: 40px;
}

#DataAcq_title__3nQTV {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 50px;
  color: #434343;
}

#DataAcq_description__2J3gI {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  color: #434343;
}

.DataAcq_questionBack__1AQwj {
  width: 100%;
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.DataAcq_btnArrow__378MS {
  width: 35%;
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  font-size: 20px;
  background: #f0f0f0;
  border-radius: 5px;
  color: #505050;
  padding: 5px 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAMCAYAAABm+U3GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADGSURBVHgBrdPRCYMwEAbgO12gIzhCV3AEJ2gFX3yqo7TvinUSV+gGzQh9TR6S3kGFYJIaMT8IIXf3cYLiMAxvACggbUSmtZ4gcdhEPvR9f0fEGySIMebRNE2HywXhT8IvcCCEToRe+Yx24Qhuo5zMLuZ53hH8gp3hGRt14LquP9RU7sG5l2ece1/zOI4nerWZnjNEoLzQupb5Bn6bV3QUEI6QUlY+NLjxEtq8oG9yBvcHEkqpsm1bEZr9CwfwTTQKXuEQg3K+zFVjX7HaH4gAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: calc(100% - 25px) center;
  padding-right: 30px;
}

.DataAcq_exportBtn__3UC_8 {
  background-color: #0096ff!important;
}

#DataAcq_btnArrow__378MS {
  width: 100px;
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  font-size: 20px;
  background: #f0f0f0;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAJCAYAAADtj3ZXAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABrSURBVHgBnZDRDYAgDERPJ3AERmAkRmEE3cCNGEE3cIR6jXxoQsV6CZC0fUfbQUQ2AAF+7SOvBf90cfw9i0/5YcPA+hGcm30wUTpgMYdgctIFGqDGA96kBQ2DPngziDxHBfWN8IhAqnCyak7ZNN/qv19tugAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) center;
  padding-right: 0px;
}

.DataAcq_tag__2gup- {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  font-size: 30px;
  /* background: #ffffff; */
  color: #585858;
  padding: 5px 10px;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 5px) center;
  padding-right: 30px;
}

#DataAcq_tag__2gup- {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  font-size: 18px;
  background: none;
  color: #585858;
  padding: 5px 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAMCAYAAABm+U3GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADGSURBVHgBrdPRCYMwEAbgO12gIzhCV3AEJ2gFX3yqo7TvinUSV+gGzQh9TR6S3kGFYJIaMT8IIXf3cYLiMAxvACggbUSmtZ4gcdhEPvR9f0fEGySIMebRNE2HywXhT8IvcCCEToRe+Yx24Qhuo5zMLuZ53hH8gp3hGRt14LquP9RU7sG5l2ece1/zOI4nerWZnjNEoLzQupb5Bn6bV3QUEI6QUlY+NLjxEtq8oG9yBvcHEkqpsm1bEZr9CwfwTTQKXuEQg3K+zFVjX7HaH4gAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 5px) center;
  padding-right: 30px;
}

#DataAcq_limit__hE81p {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #434343;
  text-align: center;
}

#DataAcq_limitLabel__U1JLJ {
  font-size: 17px;
  font-family: Mulish;
  color: #585858;
}

#DataAcq_addMore__1pNxI {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height */
  color: #9e9e9e;
}

.DataAcq_inputBox__uzEsY {
  border: none;
  background: transparent;
  color: #9e9e9e;
  border: none;
  outline: none;
}

#DataAcq_submitBtn__2B71x {
  background: #0094e7;
  /* width: 157px; */
  padding: 2px 10px;
  height: 34px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
}

#DataAcq_submitBtn__2B71x:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#DataAcq_saveBtn__26BH0 {
  background: #0096ff;
  width: 120px;
  height: 34px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
  margin-right: 20px;
}

#DataAcq_saveBtn__26BH0:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#DataAcq_launch__2K9lL {
  background: #0096ff;
  width: 120px;
  height: 40px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
}

#DataAcq_launch__2K9lL:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#DataAcq_cancelBtn__iIEdp {
  width: 80px;
  height: 34px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: #c4c4c4;
  margin-right: 30px;
}
#DataAcq_cancelBtn__iIEdp:hover {
  background-color: #ffffff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#DataAcq_cardlabel__gQ4N6 {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* identical to box height */
  text-transform: uppercase;
  color: #000000;
}

#DataAcq_cardValue__22V4G {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
  /* color: #585858; */
  color: #0096ff;
}

#DataAcq_scrollBar__3O-Od {
  overflow-y: scroll;
  height: 76.2vh;
}

#DataAcq_scrollBar__3O-Od {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#DataAcq_scrollBar__3O-Od::-webkit-scrollbar {
  display: none;
}

#DataAcq_selectBtn__38ctH {
  font-size: 16px;
  font-weight: 600;
  font-family: Mulish;
  color: black;
  text-transform: capitalize;
  background: #f0f0f0;
  border-radius: 5px;
}

#DataAcq_selectBtn__38ctH:hover {
  background-color: #0094e7;
  color: #ffffff !important;
  border-radius: 0;
}

#DataAcq_selBtn__1m1AO {
  display: flex;
  justify-content: space-around;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  text-transform: capitalize;
  /* identical to box height */
  color: #505050;
  background: #f0f0f0;
  border-radius: 5px;
}

#DataAcq_typeBtn__97OWr {
  display: flex;
  justify-content: flex-start;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  text-transform: capitalize;
  /* identical to box height */
  color: #505050;
  border-radius: 5px;
}

#DataAcq_savedVal__1I3mM {
  font-family: Mulish;
  color: #ffffff;
  background-color: #c4c4c4;
  /* margin-right: 20px; */
  font-weight: 600;
  font-size: 18px;
  padding: 5px;
  margin: 1rem 3rem 2rem 0;
}
.DataAcq_headerCardLabel__LcLre {
  font-size: 12.8px;
  padding: 5px 0 0 10px;
}

.DataAcq_headerCardValue__CBsPy {
  font-size: 24px;
  color: #0096ff;
}

.DataAcq_headerCardStatus___PTyJ {
  margin: -10px 0 0 0;
  font-size: 12px;
}

.DataAcq_headerCardTotal__3yIKl {
  font-size: 12.8px;
  margin: 0px 0 0 10px;
}

.DataAcq_valueStatus__2btTf {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 10px;
  padding-bottom: 2px;
  margin-top: -8px;
}

@media screen and (min-width: 1400px) {
  .DataAcq_headerCardValue__CBsPy {
    font-size: 34px;
  }

  .DataAcq_headerCardStatus___PTyJ {
    /* margin: -10px 0 0 0; */
    font-size: 16px;
  }

  .DataAcq_valueStatus__2btTf {
    margin: -15px 10px 0 0;
  }

  #DataAcq_submitBtn__2B71x {
    font-size: 18px;
    height: 40px;
  }

  #DataAcq_cardlabel__gQ4N6 {
    font-size: 16px;
  }

  #DataAcq_cardValue__22V4G {
    font-size: 25px;
  }

  .DataAcq_back__3qAr5,
  .DataAcq_content__u2ZBx,
  .DataAcq_questionBack__1AQwj,
  .DataAcq_formBtn__2fj7K,
  .DataAcq_saved__eQ4Wy {
    width: 956px;
  }

  #DataAcq_selBtn__1m1AO,
  #DataAcq_typeBtn__97OWr,
  #DataAcq_limitLabel__U1JLJ {
    font-size: 20px;
  }

  #DataAcq_description__2J3gI {
    font-size: 17px;
  }

  #DataAcq_title__3nQTV {
    font-size: 40px;
  }
}

.tag {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  font-size: 30px;
  background: transparent;
  color: #585858;
  padding: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAMCAYAAABm+U3GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADBSURBVHgBrY/PDcIgFIcf7QKO0BFcoSN0Am3TS092FL0TsJO4ghvICF7hgu+ZNiEUlKZ8CRzen48fTAjxstZWkBdVoHSC/EyMbs75lTF2gQyg59Z13ciWAsrvWDzBDuj3fd+fvw+4jT1yV0oUbrMsyxHFT9gI7bjSlbht2zcO1VvkNEs7q3poWEp5wK898BwhQUqB/F4RWpiTN3gUxFFa6yYkjSZewOTVnLzypcaYehgGFdv9KY7I/0qTxJ4cUqTEBzuyaWtq4bneAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: calc(100% - 5px) center;
  padding-right: 30px;
}

.btnHover {
  font-size: 16px !important;
  height: 34px;
}

.btnHover:hover {
  transition: all 0.3s ease-in-out 0.2s;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media screen and (min-width: 1400px) {
  .btnHover {
    font-size: 18px !important;
    height: 40px;
  }
}

.ListItems_name__136mm {
  font-size: 17px;
}

.ListItems_id__39kVs {
  font-size: 12px;
  margin-bottom: 0;
  text-transform: none;
}

@media screen and (min-width: 1400px) {
  .ListItems_name__136mm {
    font-size: 20px;
  }

  .ListItems_id__39kVs {
    font-size: 15px;
    text-transform: none;
  }
}

.TabPanel_tab__3H98_ {
  /* font-size: 16px !important; */
  font-size: 14px !important;
  /* padding: 20px 35px; */
  padding: 12px 7px !important;
  font-weight: 700 !important;
  font-family: Mulish !important;
}

@media screen and (min-width: 1100px) {
  .TabPanel_tab__3H98_ {
    font-size: 15px !important;
  }
}

@media screen and (min-width: 1200px) {
  .TabPanel_tab__3H98_ {
    font-size: 15px !important;
    padding: 12px 16px !important;
  }
}

@media screen and (min-width: 1200px) {
  .TabPanel_tab__3H98_ {
    font-size: 15px !important;
    padding: 12px 16px !important;
  }
}

@media screen and (min-width: 1300px) {
  .TabPanel_tab__3H98_ {
    font-size: 17px !important;
    padding: 12px 16px !important;
  }
}

@media screen and (min-width: 1400px) {
  .TabPanel_tab__3H98_ {
    font-size: 18px !important;
  }
}

.memberInfoRightCol .MuiTabs-indicator {
  width: 142px;
}

.communicationPinIcon:hover {
  background-color: #c4c4c4;
}

.sendBtn {
  height: 34px;
  font-size: 16px;
}

.sendBtn:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media screen and (min-width: 1400px) {
  .sendBtn {
    height: 40px;
    font-size: 18px;
  }
}

.Configuration_transparentButton__d-yty {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 14.2029px;
  line-height: 18px;
  /* identical to box height */
  text-transform: uppercase;
  color: #828282;
  margin-right: 10px;
}

.Configuration_normalButton__3ABKm {
  background: #c4c4c4;
  border-radius: 3.55072px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 14.2029px;
  line-height: 18px;
  /* identical to box height */
  text-transform: uppercase;
  color: #ffffff;
}

.Configuration_configLeftHead__20XKm {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #585858;
  padding: 30px 20px;
}

.Configuration_configListItem__Mt6Nr {
  font-size: 17px;
  padding: 25px 10px 15px;
}

@media screen and (min-width: 1400px) {
  .Configuration_configLeftHead__20XKm {
    font-size: 26px;
  }
  .Configuration_configListItem__Mt6Nr {
    font-size: 19px;
  }
}

.addBtn:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.tag {
    -webkit-appearance: none;
            appearance: none;
    outline: none;
    border: none;
    font-size: 30px;
    background: transparent;
    color: #585858;
    padding: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAMCAYAAABm+U3GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADBSURBVHgBrY/PDcIgFIcf7QKO0BFcoSN0Am3TS092FL0TsJO4ghvICF7hgu+ZNiEUlKZ8CRzen48fTAjxstZWkBdVoHSC/EyMbs75lTF2gQyg59Z13ciWAsrvWDzBDuj3fd+fvw+4jT1yV0oUbrMsyxHFT9gI7bjSlbht2zcO1VvkNEs7q3poWEp5wK898BwhQUqB/F4RWpiTN3gUxFFa6yYkjSZewOTVnLzypcaYehgGFdv9KY7I/0qTxJ4cUqTEBzuyaWtq4bneAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: calc(100% - 5px) center;
    padding-right: 30px;
  }
.Topnav_topNav__2WiQE {
  width: 100vw;
  background-color: #f3f3f3;
  height: 10vh;
  position: fixed;
  z-index: 5;
  padding-left: 15vw;
}

.Topnav_search__2iphR {
  /* margin-top: 3vh !important; */
  /* margin-left: 3vh !important; */
  width: 20vw;
}

.LoginForm_logoHead__3ofMj {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 41.9649px;
  line-height: 24px;
  /* identical to box height, or 58% */
  text-transform: uppercase;

  letter-spacing: 0.47em;

  color: #000000;
  margin: 10px;
}

.LoginForm_cooperative__ERUM4 {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 17.4854px;
  line-height: 24px;
  /* identical to box height, or 140% */

  letter-spacing: 0.5em;
  text-transform: uppercase;
  color: #000000;
  margin: 10px;
}
.LoginForm_main__1lrmf {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4.5rem 0 1rem 0;
  overflow: hidden;
}
.LoginForm_forgot__3FSHe {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #7c7c7c;
}


/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: Mulish;
}

/*---------------------------------------------*/
a {
  font-family: Mulish;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  text-decoration: none;
}

a:focus {
  outline: none !important;
  text-decoration: none;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-family: Mulish;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

/* input:focus::-webkit-input-placeholder {
  color: transparent;
} */
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #999999;
}
input:-moz-placeholder {
  color: #999999;
}
input::-moz-placeholder {
  color: #999999;
}
input:-ms-input-placeholder {
  color: #999999;
}

textarea::-webkit-input-placeholder {
  color: #999999;
}
textarea:-moz-placeholder {
  color: #999999;
}
textarea::-moz-placeholder {
  color: #999999;
}
textarea:-ms-input-placeholder {
  color: #999999;
}

label {
  display: block;
  margin: 0;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
  [ Utility ]*/
.txt1 {
  font-family: Mulish;
  font-size: 13px;
  color: #7c7c7c;
  line-height: 1.4;
}

.txt2 {
  font-family: Mulish;
  font-size: 13px;
  color: #999999;
  line-height: 1.4;
}

.txt3 {
  font-family: Mulish;
  font-size: 16px;
  color: #7c7c7c;
  line-height: 1.4;
}

/*//////////////////////////////////////////////////////////////////
  [ login ]*/

.limiter {
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  background-color: #ebebeb;
}

.container-login100 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.wrap-login100 {
  width: 560px;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 85px;
}

/*==================================================================
  [ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-title {
  font-family: Mulish;
  font-size: 30px;
  color: #555555;
  line-height: 1.2;
  text-align: left;
  width: 100%;
  display: block;
}

/*------------------------------------------------------------------
  [ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  background: #ffffff;
  border: 1px solid #88949e;
  box-sizing: border-box;
  border-radius: 3px;
}

/*---------------------------------------------*/
.input100 {
  display: block;
  width: 100%;
  background: transparent;
  height: 50px;
  padding: 0 20px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #88949e;
}
/*------------------------------------------------------------------
  [ Focus Input ]*/

.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #57b846;
  border-radius: 3px;

  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;

  -webkit-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}

.input100:focus + .focus-input100 {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  transform: scale(1);
}

.eff-focus-selection {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  transform: scale(1);
}

/*---------------------------------------------*/
.btn-show-pass {
  font-size: 15px;
  color: #999999;
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  right: 12px;
  padding: 0 5px;
  cursor: pointer;
  transition: background 0.4s;
}

.btn-show-pass:hover {
  color: #57b846;
}

.btn-show-pass.active {
  color: #57b846;
}

/*==================================================================
  [ Restyle Checkbox ]*/

.input-checkbox100 {
  display: none;
}

.label-checkbox100 {
  font-family: Mulish;
  font-size: 16px;
  color: #999999;
  line-height: 1.4;
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.label-checkbox100::before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 13px;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #e6e6e6;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-checkbox100:checked + .label-checkbox100::before {
  color: #555555;
}

/*------------------------------------------------------------------
  [ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #0092e8;
  color: #ffffff;
  border-radius: 3px;
}

.login100-form-btn {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 31px;
  background-color: #0092e8;
  color: #ffffff;
}

.login100-form-btn:hover {
  background-color: #57b846;
}

/*------------------------------------------------------------------
  [ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate .btn-show-pass {
  visibility: hidden;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 3px;
  padding: 4px 25px 5px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;

  font-family: Mulish;
  color: #c80000;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 18px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

/*//////////////////////////////////////////////////////////////////
  [ Responsive ]*/

@media (max-width: 576px) {
  .wrap-login100 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.btnHoverLogin:hover{
  transition: all 0.3s ease-in-out 0.2s;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}
