.tag {
  appearance: none;
  outline: none;
  border: none;
  font-size: 22px;
  background: #ffffff;
  color: #585858;
  /* padding: 5px 10px; */
  /* background-image: url(../../assets/images/caret-down.png); */
  /* background-repeat: no-repeat;
  background-size: 15px; */
  /* background-position: calc(100%) center; */
  width: 120px;
  height: 38px;
}

.btnArrow {
  appearance: none;
  outline: none;
  border: none;
  font-size: 150px;
  background: #c4c4c4;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 10px;
  background-image: url(../../assets/images/caret-down1.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 5px) center;
  padding-right: 30px;
}

.cardLabel {
  text-transform: capitalize;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  /* font-size: 14px; */
  font-size: 12px;
  line-height: 25px;
  /* identical to box height */
  color: #000001 !important;
  margin-bottom: 1rem !important;
}

.cardValue {
  /* font-size: 30px; */
  font-size: 26px;
  margin-top: -15px;
  color: #0094e7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
}

#cardImg {
  position: relative;
  bottom: 5px;
  left: 10px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

#lab {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  text-transform: capitalize;
  color: #595959;
}

#but {
  background: #c4c4c4;
  border-radius: 5px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;
}

#cardMember {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #595959;
}

#cardNumber {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 44px;
  /* text-align: center; */
  /* identical to box height */

  color: #000000;
}

#issueBtn {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 15.0725px;
  line-height: 19px;
  /* identical to box height */
  width: 104px;
  height: 30.14px;
  text-transform: uppercase;
  background: #0096FF;
  border-radius: 3.76812px;
  color: #ffffff;
}

#userLabel {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  line-height: 20px;
  text-transform: capitalize;
  /* identical to box height */
  color: #595959;
}

#errors {
  margin: 0;
  padding: 0;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  /* identical to box height */
  color: red;
}

#submitBtn {
  background: #0094e7;
  width: 138px;
  height: 40px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
}

#submitBtn:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#cancelBtn {
  width: 138px;
  height: 40px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #c4c4c4;
  margin-right: 20px;
}

#cancelBtn:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}
#headerBtn {
  background: #0096FF;
  width: 130px;
  height: 34px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 13.5px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
}

#headerBtn:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#inputField {
  font-family: Mulish;
}

#fileUploadBtn {
  background: #0096FF;
  width: 80px;
  height: 30px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 10px 20px 0 0;
  border-radius: 5px;
}

#fileUploadBtn:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#cancelFile {
  width: 80px;
  height: 30px;
  margin: 10px 20px 0 0;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
  text-transform: uppercase;
  color: #c4c4c4;
}
#cancelFile:hover {
  background-color: #ffffff;
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0.2s;
}

#scrollBar {
  overflow-y: scroll;
  /* height: 76.2vh; */
}

#scrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#scrollBar::-webkit-scrollbar {
  display: none;
}

.leftPanelHead button {
  font-size: 18px !important;
}

.memberInput {
  font-size: 13.5px !important;
}

.memberSelectField {
  font-size: 13.5px !important;
}

.memberInfoLeftCol {
  flex: 0 0 auto;
  width: 41% !important;
}

.memberInfoRightCol {
  flex: 0 0 auto;
  width: 59% !important;
}

.switchLabel {
  font-size: 17px !important;
  text-transform: capitalize;
  color: #666666;
}

@media screen and (min-width: 1024px) {
  .memberInfoLeftCol {
    flex: 0 0 auto;
    width: 38% !important;
  }
  .memberInfoRightCol {
    flex: 0 0 auto;
    width: 62% !important;
  }
  .memberInput {
    font-size: 13.5px !important;
  }
  .memberSelectField {
    font-size: 13.5px !important;
  }
  .cardLabel {
    font-size: 13px;
  }
  .cardValue {
    font-size: 24px;
  }
}

@media screen and (min-width: 1200px) {
  .memberInfoLeftCol {
    flex: 0 0 auto;
    width: 37% !important;
  }
  .memberInfoRightCol {
    flex: 0 0 auto;
    width: 63% !important;
  }
  .memberInput {
    font-size: 14px !important;
  }
  .memberSelectField {
    font-size: 14px !important;
  }
  #userLabel {
    font-size: 13px !important;
  }
  .switchLabel {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 1300px) {
  .memberInfoLeftCol {
    flex: 0 0 auto;
    width: 36% !important;
  }
  .memberInfoRightCol {
    flex: 0 0 auto;
    width: 64% !important;
  }
  #headerBtn {
    font-size: 14px;
  }
}

@media screen and (min-width: 1400px) {
  #headerBtn {
    font-size: 16px;
    height: 40px;
    width: 140px;
  }
  .leftPanelHead {
    font-size: 25px;
  }
  #userLabel {
    font-size: 15px !important;
  }
  .memberInput {
    font-size: 17px !important;
  }
  .memberSelectField {
    font-size: 17px !important;
  }
  .cardLabel {
    font-size: 14px;
  }
  .cardValue {
    font-size: 30px;
  }
  #cardImg {
    height: 30px;
    width: 30px;
  }
}

/* @media screen and (min-width: 1500px) {
  .memberInfoLeftCol {
    flex: 0 0 auto;
    width: 34% !important;
  }
  .memberInfoRightCol{
    flex: 0 0 auto;
    width: 66% !important;
  }
} */

@media screen and (min-width: 1600px) {
  .memberInfoLeftCol {
    flex: 0 0 auto;
    width: 34% !important;
  }
  .memberInfoRightCol {
    flex: 0 0 auto;
    width: 66% !important;
  }
}

@media screen and (min-width: 1700px) {
  .memberInfoLeftCol {
    flex: 0 0 auto;
    width: 33.33% !important;
  }
  .memberInfoRightCol {
    flex: 0 0 auto;
    width: 66.67% !important;
  }
}

/* @media screen and (min-width: 1800px) {
  .memberInfoLeftCol {
    flex: 0 0 auto;
    width: 28% !important;
  }
}

@media screen and (min-width: 1900px) {
  .memberInfoLeftCol {
    flex: 0 0 auto;
    width: 27% !important;
  }
} */
